<template>
  <div class="flex_column">
    <dog-table
      ref="dogTable"
      id="110f4982-a37d-4188-a347-928e076d0aad"
      :columns="tableColumns"
      :service="getList"
      column-type="selection"
      row-class-name="pointer"
      @selection-change="selectedData = $event"
      @row-click="rowClick"
    >
      <permission-button slot="btn" :config="btnConfig"> </permission-button>
    </dog-table>
    <add-class-dialog
      ref="addClassDialog"
      @confirm="searchData"
    ></add-class-dialog>
  </div>
</template>

<script>
  import classService from '@/api/service/class';
  import addClassDialog from './addClassDialog.vue';

  export default {
    name: 'classManage__leftPart',
    components: { addClassDialog },
    data() {
      return {
        tableColumns: [
          {
            prop: 'operate',
            label: '操作',
            width: 60,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <permission-element
                config={[
                  {
                    code: 'editClass',
                    callback: () =>
                      this.$refs.addClassDialog.openFrame({
                        companyId: this.companyId,
                        id: row.id
                      })
                  }
                ]}></permission-element>
            )
          },
          { prop: 'name', label: '分类名', width: 120 },
          {
            prop: 'sort',
            label: '排序',
            width: 50,
            widthGrow: false
          },
          {
            prop: 'img',
            label: '图片',
            width: 80,
            render: (h, { props: { cur } }) =>
              cur ? (
                <el-image
                  style='height: 30px; margin-right: 10px;'
                  src={cur}
                  fit='contain'
                  preview-src-list={[cur]}
                />
              ) : null
          },
          {
            prop: 'enable_cn',
            label: '启用',
            width: 70,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <dog-switch
                value={row.enable_cn}
                onInput={(val) => this.quickOperate(row.id, val)}></dog-switch>
            )
          },
          { prop: 'desc', label: '描述', width: 100 }
        ],
        btnConfig: [
          {
            buttonType: 'primary',
            code: 'addClass',
            callback: this.addClass
          },
          {
            buttonType: 'danger',
            code: 'deleteClass',
            callback: this.deleteMainClass
          }
        ],
        selectedData: [],
        companyId: null,
        total: 0
      };
    },
    methods: {
      getData(companyId) {
        this.companyId = companyId;
        this.searchData();
      },
      searchData() {
        this.$refs.dogTable.search();
      },
      getList(params) {
        params.companyId = this.companyId;
        if (!this.companyId) {
          this.total = 0;
          return Promise.resolve({ list: [], total: 0 });
        }
        return classService.getMainClassList(params).then((res) => {
          if (res.list[0]) {
            this.$refs.dogTable.$refs.baseTable.$refs.elTable.setCurrentRow(
              res.list[0]
            );
            this.$emit('select-class', res.list[0]);
          } else {
            this.$emit('select-class', {});
          }
          this.total = res.total;
          return res;
        });
      },
      quickOperate(id, val) {
        const transVal = val ? '1' : '0';
        this.$confirm(
          `是否确定${val ? '启用' : '禁用'}所选数据？${
            !val ? '（将解绑对应产品！）' : ''
          }`,
          '提示',
          {
            type: 'warning'
          }
        ).then(() => {
          classService
            .quickOperateMainClass({ id, enable: transVal })
            .then(this.searchData);
        });
      },
      deleteMainClass() {
        if (this.selectedData.length === 0) {
          return this.$message.error('请至少选择一条数据！');
        }
        this.$confirm(`是否确定删除所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          classService
            .deleteMainClass({
              ids: this.selectedData.map((item) => item.id)
            })
            .then(this.searchData);
        });
      },
      rowClick(row) {
        this.$emit('select-class', row);
      },
      addClass() {
        if (!this.companyId) {
          this.$message.error('请先维护公司信息！');
          return;
        }
        this.$refs.addClassDialog.openFrame({
          companyId: this.companyId,
          total: this.total
        });
      }
    }
  };
</script>

<style lang="scss" scoped></style>
