<template>
  <div class="flex_column classManage">
    <dog-select
      class="classManage__select"
      :service="getCompanyList"
      v-model="selectedCompany"
      :select-first="true"
      :clearable="false"
      :width="300"
      placeholder="请选择公司"
      @input="selectCompany"
    ></dog-select>
    <div class="flex_row flex_1">
      <left-part
        ref="leftPart"
        class="flex_1 flex_column classManage__left"
        @select-class="selectClass"
      ></left-part>
      <el-divider
        direction="vertical"
        class="classManage__divider"
      ></el-divider>
      <right-part
        ref="rightPart"
        class="flex_1 flex_column classManage__right"
      ></right-part>
    </div>
  </div>
</template>

<script>
  import leftPart from './leftPart.vue';
  import rightPart from './rightPart.vue';
  import companyService from '@/api/service/company';

  export default {
    name: 'classManage',
    components: { leftPart, rightPart },
    data() {
      return {
        selectedCompany: null
      };
    },
    mounted() {},
    methods: {
      getCompanyList() {
        return companyService.getOwnCompanyEnum();
      },
      selectCompany(companyId) {
        this.$refs.leftPart.getData(companyId);
      },
      selectClass(mainClass) {
        this.$refs.rightPart.getData(mainClass, this.selectedCompany);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @include b(classManage) {
    height: 100%;
    @include e(select) {
      margin-bottom: 10px;
    }
    @include e(divider) {
      height: 100%;
    }
  }
</style>
